import React, { useRef, useState } from 'react';
import localize from '../../components/_global/localization/localize';
import Spacer from '../../components/_global/layout/Spacer';
import Section from '../../components/_global/layout/Section';
import Layout from '../../components/_global/layout/Layout';
import PageHeader from '../../components/_global/layout/PageHeader';
import ButtonBlock from '../../components/_global/ButtonBlock';
import { graphql } from 'gatsby';
import Text from '../../components/_global/editorial/Text';
import ReCAPTCHA from "react-google-recaptcha";
import styled from 'styled-components';
import RoundButton from '../../components/_global/RoundButton';
import PillButton from '../../components/_global/buttons/PillButton';

const TextArea = styled.textarea`
  border-radius: 4px;
  border: 2px solid ${p => p.error ? p.theme.colors.error : p.theme.colors.main01};
  background-color: ${p => p.error ? p.theme.colors.errorPale : p.theme.colors.titanWhite};
  width: 100%;
  max-width: 50rem;
  min-height: 20rem;
  padding: 20px 30px;
  font-size: ${({ theme }) => theme.text.medium.size.regular};
  font-family: ${({ theme }) => theme.text.medium.family}, sans-serif;
  color: ${({ theme }) => theme.colors.main01};
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.periwinkleGray};
    opacity: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    //height: 2.5rem; // 40px
    width: 100%;
    font-size: ${({ theme }) => theme.text.medium.size.small};
  }
`

const Ask = ({ data }) => {
  const { page } = data;
  const [message, setMessage] = useState("")
  const [captcha, setCaptcha] = useState(null)
  const capRef = useRef(null)
  const [sent, setSent] = useState(false)

  const handleSendMessage = async (event) => {
    event.preventDefault();

    // POST request
    if (message && captcha) {
      setSent(true)
      await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify({
          message: event.target.elements.message.value
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });

      setMessage("")
      setCaptcha(null)
      // await capRef.current.reset()
    }
  };

  return (
    <Layout title={page.common.title}>
      <PageHeader title={page.pageTitle} image={page.splashImage} text={page.splashTitle} />


      <Spacer s="medium" />
      <Section m="medium">
        <Text s="h2" as="h2" c="main01">
          {page.askTitle}
        </Text>
        <Spacer s="medium" />

        <Text s="standard">
          {page.askText}
        </Text>
      </Section>
      <Spacer s="large" />

      <Section m="medium">

        {!sent ? <>
          <Text s="standard">
            {page.askFieldTitle}
          </Text>
          <Spacer s="small" />

          <form
            method='post'
            target="_blank"
            onSubmit={handleSendMessage}
          >
            <TextArea
              onChange={(event) => setMessage(event.target.value)}
              name="message"
              value={message}
              placeholder={page.askFieldPlaceholder}
            >

            </TextArea>
            <Spacer s="large" />

            <ReCAPTCHA
              ref={capRef}
              onChange={(val) => setCaptcha(val)}
              sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
            />
            <Spacer s="large" />

            <PillButton
              as="button"
              type='submit'
              disabled={!captcha || !message}
              style={{ width: "fit-content" }}
            >
              {page.askButton}
            </PillButton>
          </form>
        </> : <Text s="standard">{page.askQuestionAskedText}</Text>}
      </Section>
      <Spacer s="large" />

      <Section m="small">
        <ButtonBlock
          title={page.askFocusTitle}
          text={page.askFocusText}
          button={page.askFocusButton}
          link='questions'
        />
      </Section>
      <Spacer s="large" />
      <Spacer s="large" />
      <Spacer s="large" />
    </Layout>
  );
};

export default localize(Ask);

export const query = graphql`
    {
        page: sanityQuestions {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            pageTitle {
              _type
              fr
            }
            splashImage
            {
                ...
                ImageFragment
            }
            splashTitle {
              _type
              fr
            }
            askTitle {
              _type
              fr
            }
            askText {
              _type
              fr
            }
            askFieldTitle {
              _type
              fr
            }
            askFieldPlaceholder {
              _type
              fr
            }
            askButton {
              _type
              fr
            }
            askFocusTitle {
              _type
              fr
            }
            askFocusText {
              _type
              fr
            }
            askFocusButton {
              _type
              fr
            }
            askQuestionAskedText {
              _type
              fr
            }
        }
    }
`;